<template>
  <div class="rsf-country-select-wrapper relative">
    <svg class="svg-color-14" :style="`transform: rotate(180deg) translateY(50%)`" width="24" height="24" viewBox="0 0 24 24"
          fill="none">
          <path d="M17 15L12 10L7 15" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
  <select @change="checkUpdateCountry(chosenCountry)" v-model="chosenCountry" class="rsf-country-select color-14 pointer h5 open-sans-regular">
    <option :value="{}" disabled selected hidden>{{$t('rsf_compare_retail_price_country_select_placeholder')}}</option>
    <option v-for="country in countries" :key="country.id" :value="country">{{country.countryname}}</option>
  </select>
</div>
</template>

<script>
import axios from 'axios'
import { EventBus } from '@/event-bus'

export default {
  name: 'CountrySelect',
  props: {
    updateCountry: Function,
    siteLanguage: String,
    selectedCountry: Object,
    countries: Array
  },
  data() {
    return {
      chosenCountry: {}
    }
  },
  created() {
    this.chosenCountry = this.selectedCountry
    EventBus.$on('update_chosen_country', (payload) => {
      console.log('received update chosen country');
      console.log(payload)
      for(let i = 0; i < this.countries.length; i++){
        if(this.countries[i].isocode2 == payload.isocode){
          this.chosenCountry = this.countries[i]
        }
      }
    })
  },
  methods: {
    checkUpdateCountry(country){
      if(country.isocode2 != this.selectedCountry.isocode2){
        this.updateCountry(country)
      }
    }
  }
}
</script>

<style scoped>

</style>
