<template>
  <div>
    <div v-if="isDevSystem == 'true'" class="py2 bg-color-6 center color-white">{{ devWarnMsg }}</div>
    <main>
      <router-view :categories="categories" :formatCurrency="formatCurrency" :loadingCategories="loadingCategories"
        :siteLanguage="siteLanguage" :selectedCountry="selectedCountry" :updateCountry="updateCountry"
        :measurement="measurement" :countries="countries" :compareId="compareId" :heroImage="heroImage" :headline="headline">
      </router-view>
    </main>
    <CompareModal ref="compare-ar-modal">
      <p class="h1 center open-sans-bold color-black pt2 px2 pb2">{{ $t('compare_scan_qr_line') }}</p>
      <!-- <p class="h4 center opens-sans-regular color-black px2 pb3">Please scan the QR-Code below with an AR-ready device. Thank you!</p> -->
      <img :src="arQrCode" class="contain col-12 block mx-auto pb3" style="max-width: 300px; height: 300px;" />
      <!-- <P class="pb3 open-sans-regular h4 color-black px2 center">Or <a class="color-1 open-sans-regualr">click here</a> to see list with devices which support AR.</P> -->
    </CompareModal>
  </div>
</template>

<script>

import axios from "axios";
import { EventBus } from "./event-bus";
import CompareModal from "./components/CompareModal.vue";

export default {
  name: 'App',
  props: ["siteLanguage", "compareId"],
  data() {
    return {
      isDevSystem: process.env.VUE_APP_RSF_DEV_SYSTEM_WARNING,
      devWarnMsg: process.env.VUE_APP_RSF_DEV_SYSTEM_MESSAGE,
      categories: [],
      loadingCategories: true,
      selectedCountry: {},
      measurement: 'metric',
      countries: [],
      heroImage: '',
      headline: '',
      arQrCode: '',
    }
  },
  components: {
    CompareModal
  },
  created() {

    if (this.siteLanguage == '') {
      this.$i18n.locale = 'en';
      this.switchLocale();
    } else {
      this.$i18n.locale = this.siteLanguage;
      this.switchLocale();
    }

    EventBus.$on('open-ar-modal', (payload) => {
      console.log('open ar modal')
      this.arQrCode = payload.arQrCode;
      this.$refs['compare-ar-modal'].open();
    })

  },
  mounted() {
    let that = this
    this.getNavBarHeight();
    window.addEventListener('resize', () => {
      that.getNavBarHeight()
    })
    axios
      .post(process.env.VUE_APP_RSF_API + "/public-api/list-countries/compare/json", { 'lang': this.siteLanguage })
      .then(function (response) {
        that.countries = response.data.data;
        for (let i = 0; i < that.countries.length; i++) {
          if (that.countries[i].isocode2 == 'XX') {
            that.countries.splice(i, 1);
          }
          if (that.countries[i].isocode2 == 'JA') {
            that.countries.splice(i, 1);
          }
          if(that.countries[i].isocode2 == 'CN' && process.env.VUE_APP_RSF_CHINA_VARIANT == 'false'){
            that.countries.splice(i, 1);
          }
        }
        that.preSelectCountry();
        // that.selectedCountry = response.data.data[0]
        that.fetchData();

      })
      .catch(function (error) {

      });
  },
  methods: {
    getNavBarHeight(){
      let navBar = document.getElementsByClassName('rsf-nav');
      let secondBar = document.getElementsByClassName('rsf-second-navbar');
      let navBarHeight = 0;
      let secondBarHeight = 0;
      if (navBar.length > 0) {
        navBarHeight = navBar[0].offsetHeight;
      }
      if (secondBar.length > 0) {
        secondBarHeight = secondBar[0].offsetHeight;
      }
      let navHeight = navBarHeight + secondBarHeight + 1
      document.documentElement.style.setProperty('--top-menu-height', navHeight + "px");
      console.log('NAVHEIGHT')
      console.log(navHeight)
    },
    preSelectCountry() {
      console.log('preselect country')
      for (let i = 0; i < this.countries.length; i++) {
        if (this.countries[i].preselection_by_ip) {
          console.log('prselected by IP!')
          console.log(this.countries[i].isocode2)
          this.selectedCountry = this.countries[i];
          this.updateCountry(this.countries[i]);
          if(this.countries[i].isocode2 == 'US'){
            console.log('us - switch to imperial')
            this.measurement = 'imperial'
          }else{
            console.log('use metric system')
            this.measurement = 'metric'
          }
          return;
        }
      }

      console.log('no preselection by ip, checking site locale')

      if (this.siteLanguage == 'cn') {
        this.findCountry('CN')
        return;
      }
      else if (this.$i18n.locale == 'de') {
        this.findCountry('DE')
      }
      else if (this.$i18n.locale == 'fr') {
        this.findCountry('FR')
      }
      else if (this.$i18n.locale == 'en') {
        this.findCountry('GB')
      }
      else if (this.$i18n.locale == 'br') {
        this.findCountry('GB')
      }
      else if (this.$i18n.locale == 'ja') {
        this.findCountry('GB')
      } else {
        console.log('use fall back country')
        this.findCountry('US') // fallback US
      }
    },
    findCountry(isocode) {
      console.log('find country: ' + isocode)
      for (let a = 0; a < this.countries.length; a++) {
        if (this.countries[a].isocode2 == isocode) {
          this.selectedCountry = this.countries[a]
          this.updateCountry(this.countries[a]);
        }
      }
    },
    switchLocale() {

      var locale = this.$i18n.locale;
      const to = this.$router.resolve({ params: { lang: locale } })
      this.$router.push(to.location).catch(err => {
        if (
          err.name !== 'NavigationDuplicated' &&
          !err.message.includes('Avoided redundant navigation to current location')
        ) {
          // But print any other errors to the console
          //console.log(err);
        }
      })
    },
    updateCountry(v) {
      this.selectedCountry = v;
      EventBus.$emit('update_chosen_country', {isocode: v.isocode2});
      setTimeout(() => {
        EventBus.$emit('update_retail_price');
      }, 1)

    },
    formatCurrency(currency, price, isocode) {
      let hasDecimal = false;
      let splitPrice = price.toString().split('.');
      if (parseFloat(splitPrice[1]) != 0 && splitPrice.length > 1) {
        hasDecimal = true;
      }

      if (currency == 'EUR') {
        if (!hasDecimal) {
          return `€${parseFloat(price)}`
        } else {
          let roundedPrice = parseFloat(price).toFixed(2).toString();
          let euroPrice = roundedPrice.split('.');
          let commaPrice = euroPrice[0] + ',' + euroPrice[1];
          return `€${commaPrice}`
        }
      }
      if (currency == 'JPY') {
        return `¥${price}`
      }
      if (currency == 'AUD') {
        if (!hasDecimal) {
          return `$${parseFloat(price)}`
        } else {
          return `$${parseFloat(price).toFixed(2)}`
        }
      }

      if (currency == 'USD' && isocode == 'CA') {
        if (!hasDecimal) {
          return `USD ${parseFloat(price)}`
        } else {
          return `USD${parseFloat(price).toFixed(2)}`
        }
      }

      if (currency == 'USD') {
        if (!hasDecimal) {
          return `$${parseFloat(price)}`
        } else {
          return `$${parseFloat(price).toFixed(2)}`
        }
      }

      if (currency == 'GBP') {
        if (!hasDecimal) {
          return `£${parseFloat(price)}`
        } else {
          return `£${parseFloat(price).toFixed(2)}`
        }
      }
    },
    fetchData() {
      let currentObj = this;
      this.loadingCategories = true;
      setTimeout(() => {
        axios
          .post(process.env.VUE_APP_RSF_API + "/public-api/comparisons/get-comparison/" + this.compareId + "/json", { lang: this.siteLanguage, isocode: this.selectedCountry.isocode2 })
          .then(function (response) {
            currentObj.categories = response.data.dataCategories;
            currentObj.heroImage = response.data.compareData.headImage_s3path
            currentObj.headline = response.data.compareData.headLine
            console.log(response)
            setTimeout(() => { currentObj.loadingCategories = false }, 500)
            if(currentObj.selectedCountry.isocode2 != ''){
              EventBus.$emit('fetch_detailed_data')
            }
          })
          .catch(function (error) {

          });
      }, 300)
    },
  }
}
</script>

<style>
@import './css/redsea-compare.css';

#app {
  font-family: 'Open Sans', sans-serif;
  min-height: 100vh;

}
</style>
