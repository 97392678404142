<template>
  <div class="rsf-table-cell col-12">
    <p class="open-sans-var h5 color-black center">{{
      measurement == 'metric' && metricValue && metricValue != '' ? metricValue + metricSuffix
      :
      imperialValue && imperialValue != '' ? imperialValue + imperialSuffix
      : '-'
    }}</p>
  </div>
</template>

<script>

export default {
  name: 'UnitCell',
  props: {
    measurement: String,
    metricValue: String,
    imperialValue: String,
    metricSuffix: String,
    imperialSuffix: String
  }
}
</script>
