<template>
    <div :class="`baby-compare-models-btn flex flex-column items-center bg-color-white pointer ${showTable == id ? 'active' : ''}`" @click="setTable(id)">
        <p class="h3 helvetica-bold" style="margin-bottom: 4px;">{{headline}}</p>
        <p class="h4 helvetica-regular">{{subline}}</p>
    </div>
</template>
  
<script>

export default {
    name: 'ModelsBtn',
    data() {
        return {
        }
    },
    components: {
    },
    props: {
        headline: String,
        subline: String,
        setTable: Function,
        id: String,
        showTable: String
    },
}
</script>
  
<style>

</style>
  