import BabyCompare from './components/BabyCompare.vue'
import { i18n } from "./main.js";

export default [
    { 
        path: '/:lang', 
        component: BabyCompare,
    },
    {
      path: "*",
      redirect: "/en"
    }
]