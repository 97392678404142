<template>
    <div class="bg-color-11 pt4 pb5">
        <div class="max-width-6 mx-auto px2 border-box">
            <h1 class="h2 lg-h0 center helvetica-bold mb3 lg-mb0">{{ headline }}</h1>
            <img class="mx-auto contain col-12 max-width-4 hide lg-block" :src="heroImage" />
            <div class="flex justify-center gap2 mt3 mb4 flex-wrap" v-if="isDesktop">
                <ModelsBtn 
                    v-for="category in categories"
                    :key="category.id"
                    :headline="category.categoryname" 
                    :subline="category.subtitle" 
                    :setTable="setTable"
                    :id="category.id"
                    :showTable="showTable"
                />
            </div>
        </div>
        <div class="rsf-baby-compare-table-wrapper-mobile px2 flex flex-column" v-if="!isDesktop">
            <ModelsCard 
                v-for="(category, index) in mobileCategories"
                :key="category.id"
                :headline="category.categoryname" 
                :subline="category.subtitle"
                :imgSrc="category.s3path" 
                :id="category.id"
                :index="index"
                :siteLanguage="siteLanguage"
            >
                <BabyCompareTable 
                    v-for="(subCategory, index) in category.subCategories"
                    :key="index"
                    :category="category"
                    :siteLanguage="siteLanguage"
                    :formatCurrency="formatCurrency"
                    :updateCountry="updateCountry"
                    :selectedCountry="selectedCountry"
                    :measurement="measurement"
                    :countries="countries"
                    :isDesktop="isDesktop"
                    :index="index"
                    :compareId="compareId"
                />
            </ModelsCard>
        </div>
        <div class="gridbox rsf-baby-compare-table-wrapper-desktop" v-if="isDesktop">
            <div v-for="category in categories" :key="category.id" :class="`rsf-baby-compare-table-slide gridbox-element ${showTable == category.id ? 'active' : ''}`">
                <BabyCompareTable 
                    :category="category"
                    :siteLanguage="siteLanguage"
                    :formatCurrency="formatCurrency"
                    :updateCountry="updateCountry"
                    :selectedCountry="selectedCountry"
                    :measurement="measurement"
                    :countries="countries"
                    :isDesktop="isDesktop"
                    :compareId="compareId"
                />
            </div>
        </div>
    </div>
</template>
  
<script>
import { EventBus } from '@/event-bus';
import BabyCompareTable from './BabyCompareTable.vue';
import ModelsBtn from './ModelsBtn.vue';
import ModelsCard from './ModelsCard.vue';


export default {
    name: 'BabyCompare',
    data() {
        return {
            showTable: '',
            isDesktop: true,
            mobileCategories: []
        }
    },
    components: {
        ModelsBtn,
        BabyCompareTable,
        ModelsCard
    },
    created(){
            window.addEventListener("resize", () => {this.setViewportBreakpoint()});
        },
    mounted(){
        this.setViewportBreakpoint();
    },
    methods: {
        createSubCategories(){
            this.mobileCategories = this.categories
            var splitPairs = function(arr) {
                var pairs = [];
                for (var i=0 ; i<arr.length ; i+=2) {
                    if (arr[i+1] !== undefined) {
                        pairs.push ([arr[i], arr[i+1]]);
                    } else {
                        pairs.push ([arr[i]]);
                    }
                }
                return pairs;
            };
            for(let i = 0; i < this.mobileCategories.length; i++){
                this.mobileCategories[i].subCategories = [];
                this.mobileCategories[i].subCategories = splitPairs(this.mobileCategories[i].dataProducts)
            }
        },
        setTable(v){
            this.showTable = v;
        },
        setViewportBreakpoint(){
            let width = Math.max(
                document.body.scrollWidth,
                document.documentElement.scrollWidth,
                document.body.offsetWidth,
                document.documentElement.offsetWidth,
                document.documentElement.clientWidth
            );

            if(width < 1024){
                this.isDesktop = false;
            }else{
                this.isDesktop = true;
            }
        }
    },
    props: {
        categories: Array,
        siteLanguage: String,
        formatCurrency: Function,
        updateCountry: Function,
        selectedCountry: Object,
        measurement: String,
        countries: Array,
        compareId: String,
        heroImage: String,
        headline: String
    },
    watch: {
        categories(){
            console.log('catgeories changed!!')
            if(this.categories.length > 0){
                this.createSubCategories()
            }
            if(this.isDesktop){
                this.showTable = this.categories[0].id
            }   
        }
    }
}
</script>
  
<style></style>
  